
ion-app.largefonts {
  font-size: 150%;
}


.largefonts ion-item {
  font-size: 110%;
}

.largefonts ion-badge {
  font-size: 105%;
}

.largefonts ion-button {
  font-size: 100%;
}

.largefonts ion-label {
  font-size: 100%;
}

.largefonts ion-label h2 {
  font-size: 100%;
}

.largefonts .sc-ion-label-ios-s p {
  font-size: 90%;
}

