$blue:       #20a8d8 !default;
$indigo:     #6610f2 !default;
$purple:     #6f42c1 !default;
$pink:       #e83e8c !default;
$red:        #f86c6b !default;
$orange:     #f8cb00 !default;
$yellow:     #ffc107 !default;
$green:      #4dbd74 !default;
$teal:       #20c997 !default;
$cyan:       #17a2b8 !default;
$light-blue: #63c2de !default;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/vendor/rfs";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/utilities/background";
@import "~bootstrap/scss/utilities/borders";
@import "~bootstrap/scss/utilities/display";
@import "~bootstrap/scss/utilities/flex";
@import "~bootstrap/scss/utilities/sizing";
@import "~bootstrap/scss/utilities/spacing";
@import "~bootstrap/scss/utilities/text";

@import './largeFonts';

.bg-blue { background-color: $blue !important; }
.bg-indigo { background-color: $indigo !important; }
.bg-purple { background-color: $purple !important; }
.bg-pink { background-color: $pink !important; }
.bg-red { background-color: $red !important; }
.bg-orange { background-color: $orange !important; }
.bg-yellow { background-color: $yellow !important; }
.bg-green { background-color: $green !important; }
.bg-teal { background-color: $teal !important; }
.bg-cyan { background-color: $cyan !important; }
.bg-white { background-color: $white !important; }
.bg-gray { background-color: $gray-600 !important; }
.bg-gray-dark { background-color: $gray-800 !important; }

.bg-white-half-transparent {
  background-color: rgba(255, 255, 255, .5);
}

.qr-border {
  border: 10px solid #000000 !important;
}

.overflow-yonly-auto {
  overflow-y: auto;
  overflow-x: hidden;
}

.activation_code_input {
  font-size: 22px;
}

.customer-dropdown {
  --width: 90%;
}

.logo-xs { max-height: 30px; max-width: 30px; height: 100%; width: 100%; }
.logo-sm { max-height: 50px; max-width: 50px; height: 100%; width: 100%; }
.logo-md { max-height: 100px; max-width: 100px; height: 100%; width: 100%; }
.logo-lg { max-height: 150px; max-width: 150px; height: 100%; width: 100%; }
.logo-xl { max-height: 200px; max-width: 200px; height: 100%; width: 100%; }

.avatar-md {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
}

.avatar-lg {
  position: relative;
  display: inline-block;
  width: 72px;
  height: 72px;
}

.avatar-sm {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
}

.avatar-xs {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.avatars-stack .avatar-md {
  margin-right: -18px;
  transition: margin-right 0.25s;
}

.avatars-stack .avatar:hover {
  margin-right: 0;
}

.avatars-stack .avatar-lg {
  margin-right: -36px;
}

.avatars-stack .avatar-sm {
  margin-right: -12px;
}

.avatars-stack .avatar-xs {
  margin-right: -10px;
}

.ion-gray {
  --ion-background-color: #e4e5e6;
}

.textbreak-wrap

.float-right {
  float: right !important;
}

.text-value {
  font-size: 1.3125rem;
  font-weight: 600;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

// Blockquotes
.blockquote {
  margin-bottom: $spacer;
  @include font-size($blockquote-font-size);
}

.blockquote-footer {
  display: block;
  @include font-size($blockquote-small-font-size);
  color: $blockquote-small-color;

  &::before {
    content: "\2014\00A0"; // em dash, nbsp
  }
}

.md.ion-padding-bottom-md {
  --padding-bottom: var(--ion-padding, 16px);
  padding-bottom: var(--ion-padding, 16px);
}

.active-menu-icon {
  color: $primary;
}

.ion-rounded-0 {
  --border-radius: 0;
}

.dropdown-popover {
  --width: auto;
}

small,
.small {
  @include font-size($small-font-size);
  font-weight: $font-weight-normal;
}

.text-muted {
  color: #6c757d !important;
}

.text-preline {
  white-space: pre-line;
}

.text-underline {
  text-decoration: underline;
}

.text-hover-primary:hover {
  color: #167495 !important;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.overflow-hidden {
  overflow: hidden !important;
}

.rounded-1 {
  border-radius: 0.5rem !important;
}

.rounded-2 {
  border-radius: 1rem !important;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.mw-50 {
  max-width: 50% !important;
}

.mw-75 {
  max-width: 75% !important;
}

.avatar {
  vertical-align: bottom;
}

.avatar-auto {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
}

 // round buttons from https://www.geeksforgeeks.org/how-to-get-circular-buttons-in-bootstrap-4/
.btn-circle.btn-sm {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  font-size: 8px;
  text-align: center;
}
.btn-circle.btn-md {
  width: 50px;
  height: 50px;
  padding: 7px 10px;
  border-radius: 25px;
  font-size: 10px;
  text-align: center;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 12px;
  text-align: center;
}

//
// drop zone file upload
//

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

