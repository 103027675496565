@import '../App';
//
// ios style chat bubbles inspired by https://codepen.io/swards/pen/gxQmbj
//

.bubblerow {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    margin-right: 1rem;
}

.bubblerow.mine {
    align-items: flex-end;
    margin-left: 3rem;
}

.bubblerow.create {
    margin-left: 1rem;
}

.bubblerow.yours {
    align-items: flex-start;
    margin-right: 3rem;
}


.bubble {
 //   max-width: 100%;
    word-wrap: break-word;
    border: none;
    min-width: 0;
    border-radius: 1rem !important;
    background-color: white;
    background-attachment: fixed;
    position: relative;
    padding: .75rem 1rem .75rem 1rem;
}

.short-text textarea {
    --padding-bottom: 0.25rem;
    --padding-end: 0.25rem;
    --padding-start: 0.25rem;
    --padding-top: 0.25rem;
}

.bubble a {
    text-decoration: underline;
}

.mine .bubble {
    background-color: $blue;
    color: white;
}

.mine.uneditable .bubble {
    background-color: $light-blue;
}

.mine .bubble a {
    color: #f0f3f5 !important;
    text-decoration: underline;
}

.mine .bubble a:hover, .mine .bubble a:focus {
    color: #c2ced6 !important;
}

.create .bubble {
    background-color: $gray-300;
    color: $gray-900;
    width: 100%;
}

.yours .bubble {
    background-color: $gray-300;
    color: $gray-900;
}

.yours .bubble a {
    color: $blue !important;
}

.yours .bubble a:hover, .yours .bubble a:focus {
    color: $cyan !important;
}

.mine.last .bubble:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: -8px;
    height: 15px;
    width: 20px;
    background-color: $blue;
    background-attachment: fixed;
    border: none;
    border-bottom-left-radius: 15px;
}

.mine.last.uneditable .bubble:before {
    background-color: $light-blue;
}

.mine.last .bubble:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
    width: 10px;
    height: 15px;
    background-color: white;
    border: none;
    border-bottom-left-radius: 10px;
}

.yours.last .bubble:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -7px;
    height: 15px;
    width: 20px;
    border: none;
    background-color: $gray-300;
    border-bottom-right-radius: 15px;
}
.yours.last .bubble:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -10px;
    width: 10px;
    height: 15px;
    background-color: white;
    border: none;
    border-bottom-right-radius: 10px;
}

@media (prefers-color-scheme: dark) {
    .mine.last .bubble:after {
        background-color: black;
    }

    .yours.last .bubble:after {
        background-color: black;
    }
}
